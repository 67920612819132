import './admin-dashboard.style.css';
import { Card,Row,Col } from 'react-bootstrap';
import { Fragment, useContext } from 'react';
import { UserContext } from '../../contexts/user.contexts';
import AlertPop from '../../alert/alert.component';

const AdminDashboard = ()=>{

    const {currentUser} = useContext(UserContext);
    
    return (
        <Fragment>
        {
            !currentUser ? <AlertPop />
            : 
            <div className='dashboard'>
                <Row xs={1} md={2} className="g-4">
      
      <Col>
        <Card>
          <Card.Body>
            <Card.Title>Slider</Card.Title>
            <Card.Text>
            <Card.Link href='/update-slider'>
              Click on this card to update slider image.
              </Card.Link>
            </Card.Text>
          </Card.Body>
      
          
        </Card>
      </Col>

      <Col>
        <Card>
          <Card.Body>
            <Card.Title>Home Intro</Card.Title>
            <Card.Text>
              <Card.Link href='/update-intro-cards'>Click on this card to update texts of home intro.</Card.Link>
              
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card>
          <Card.Body>
            <Card.Title>Courses</Card.Title>
            <Card.Text>
             <Card.Link href='/update-courses'>Click on this card to update Courses.</Card.Link> 
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card>
          <Card.Body>
            <Card.Title>Footer</Card.Title>
            <Card.Text>
             <Card.Link href='/update-footer'>Click on this card to update Footer.</Card.Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card>
          <Card.Body>
            <Card.Title>Contact</Card.Title>
            <Card.Text>
             <Card.Link href='update-contact'>Click on this card to update Contact.</Card.Link> 
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card>
          <Card.Body>
            <Card.Title>Testimonial</Card.Title>
            <Card.Text>
             <Card.Link href='create-testimonial'>Click on this card to update Testimonial.</Card.Link> 
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    
  </Row>
        </div>

        }

</Fragment>
        
    
    )

}

export default AdminDashboard;