import { Fragment, useState,useContext } from "react";
import { UserContext } from "../../../contexts/user.contexts";
import './courses.update.css';
import { courseCard1Title,courseCard2Title,courseCard3Title,
    courseCard4Title,courseCard5Title,courseCard6Title,
    homeCourseCard1Description,homeCourseCard2Description,
    homeCourseCard3Description,homeCourseCard4Description,
    homeCourseCard5Description,homeCourseCard6Description,
    updateCoursesTitleFromBackend,updateCoursesDescriptionFromBackend 
} from "../../../firebase/firebase";

import AlertPop from "../../../alert/alert.component";

import { Button } from "react-bootstrap";




const CoursesUpdate = ()=> {

    const {currentUser} = useContext(UserContext);

    const [coursesTitle,SetCoursesTitle] = useState('');

    const [coursesDescription,SetCoursesDescription] = useState('')

    const [card1Title,SetCard1Title] = useState('');

    const [card1Description,SetCard1Description] = useState('');

    const [card2Title,SetCard2Title] = useState('');

    const [card2Description,SetCard2Description] = useState('');

    const [card3Title,SetCard3Title] = useState('');

    const [card3Description,SetCard3Description] = useState('');

    const [card4Title,SetCard4Title] = useState('');
    
    const [card4Description,SetCard4Description] = useState('');


    const [card5Title,SetCard5Title] = useState('');
    
    const [card5Description,SetCard5Description] = useState('');


    const [card6Title,SetCard6Title] = useState('');
    
    const [card6Description,SetCard6Description] = useState('');

    const updateCoursesTitle = (event)=>{
        const updatedTitle = event.target.value;
        SetCoursesTitle(updatedTitle);
        
    }

    const updateCard1Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard1Title(updatedTitle);
        
    }

    const updateCard2Title = (event)=>{
        const updated2Title = event.target.value;
        SetCard2Title(updated2Title);
        
    }

    const updateCard3Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard3Title(updatedTitle);
        
    }

    const updateCard4Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard4Title(updatedTitle);
        
    }

    const updateCard5Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard5Title(updatedTitle);
        
    }

    const updateCard6Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard6Title(updatedTitle);
        
    }

    const updateCoursesDescription = (event)=>{
        const updatedDescription = event.target.value;
        SetCoursesDescription(updatedDescription);
        

    }


    const updateCard1Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard1Description(updatedDescription);
        

    }

    const updateCard2Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard2Description(updatedDescription);
        

    }

    const updateCard3Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard3Description(updatedDescription);
        

    }

    const updateCard4Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard4Description(updatedDescription);
        
    }

    const updateCard5Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard5Description(updatedDescription);
        
    }

    const updateCard6Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard6Description(updatedDescription);
        
    }

    const submitCoursesTitle=(event)=>{
        event.preventDefault();
        updateCoursesTitleFromBackend(coursesTitle);
        alert('Title Updated');
        SetCoursesTitle('');    

    }
    
    const submitCoursesDescription=(event)=>{
        event.preventDefault();
        updateCoursesDescriptionFromBackend(coursesDescription);
        alert('Title Updated');
        SetCoursesDescription('');    

    }

    const submitIntroCard1Title=(event)=>{
        event.preventDefault();
        courseCard1Title(card1Title);
        alert('Title Updated');
        SetCard1Title('');    

    }

    const submitIntroCard2Title=(event)=>{
        event.preventDefault();
        courseCard2Title(card2Title);
        alert('Title Updated');
        SetCard2Title('');    

    }

    const submitIntroCard3Title=(event)=>{
        event.preventDefault();
        courseCard3Title(card3Title);
        alert('Title Updated');
        SetCard3Title('');    

    }

    const submitIntroCard4Title=(event)=>{
        event.preventDefault();
        courseCard4Title(card4Title);
        alert('Title Updated');
        SetCard4Title('');    

    }

    const submitIntroCard5Title=(event)=>{
        event.preventDefault();
        courseCard5Title(card5Title);
        alert('Title Updated');
        SetCard5Title('');    

    }

    const submitIntroCard6Title=(event)=>{
        event.preventDefault();
        courseCard6Title(card6Title);
        alert('Title Updated');
        SetCard6Title('');    

    }

    const submitIntroCard1Description=(event)=>{
        event.preventDefault();
        homeCourseCard1Description(card1Description);
        alert('Description Updated');
        SetCard1Description('');  

    }

    const submitIntroCard2Description=(event)=>{
        event.preventDefault();
        homeCourseCard2Description(card2Description);
        alert('Description Updated');
        SetCard2Description('');  

    }

    const submitIntroCard3Description=(event)=>{
        event.preventDefault();
        homeCourseCard3Description(card3Description);
        alert('Description Updated');
        SetCard3Description('');  

    }

    const submitIntroCard4Description=(event)=>{
        event.preventDefault();
        homeCourseCard4Description(card4Description);
        alert('Description Updated');
        SetCard4Description('');  

    }

    const submitIntroCard5Description=(event)=>{
        event.preventDefault();
        homeCourseCard5Description(card5Description);
        alert('Description Updated');
        SetCard5Description('');  

    }

    const submitIntroCard6Description=(event)=>{
        event.preventDefault();
        homeCourseCard6Description(card6Description);
        alert('Description Updated');
        SetCard6Description('');  

    }




    return (
        
        <Fragment>
            {
                currentUser 
                ? <div>
                <h1 style={{textAlign:'center',textDecoration:'underline'}}>Update Courses Data</h1>
            <div className="courses-data">
                    <h3>Courses Title:</h3>
    
                    <form onSubmit={submitCoursesTitle}>
                        <input type='text' value={coursesTitle} onChange={updateCoursesTitle}/>
                       <Button type="submit" variant="warning">Save</Button>
                    </form>
    
                    <h3>Description:</h3>
    
                    <form onSubmit={submitCoursesDescription}>
                        <input type='text' value={coursesDescription} onChange={updateCoursesDescription}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
    
            </div>
    
            <div className="course-update">
                
                <div>
                    <h3>First Card</h3>
                    <p>Title:</p>
    
                    <form onSubmit={submitIntroCard1Title}>
                        <input type='text' value={card1Title} onChange={updateCard1Title}/>
                       <Button type="submit" variant="warning">Save</Button>
                    </form>
    
                    <p>Description:</p>
    
                    <form onSubmit={submitIntroCard1Description}>
                        <input type='text' value={card1Description} onChange={updateCard1Description}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
                
    
    
                </div> 
    
                <div>
                    <h3>Second Card</h3>
                    <p>Title:</p>
    
                    <form onSubmit={submitIntroCard2Title}>
                        <input type='text' value={card2Title} onChange={updateCard2Title}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
    
                    <p>Description:</p>
    
                    <form onSubmit={submitIntroCard2Description}>
                        <input type='text' value={card2Description} onChange={updateCard2Description}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
                
    
    
                </div> 
    
                <div>
                    <h3>Third Card</h3>
                    <p>Title:</p>
    
                    <form onSubmit={submitIntroCard3Title}>
                        <input type='text' value={card3Title} onChange={updateCard3Title}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
    
                    <p>Description:</p>
    
                    <form onSubmit={submitIntroCard3Description}>
                        <input type='text' value={card3Description} onChange={updateCard3Description}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
                
    
    
                </div> 
    
                <div>
                    <h3>Fourth Card</h3>
                    <p>Title:</p>
    
                    <form onSubmit={submitIntroCard4Title}>
                        <input type='text' value={card4Title} onChange={updateCard4Title}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
    
                    <p>Description:</p>
    
                    <form onSubmit={submitIntroCard4Description}>
                        <input type='text' value={card4Description} onChange={updateCard4Description}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
                
    
    
                </div> 
    
                <div>
                    <h3>Fifth Card</h3>
                    <p>Title:</p>
    
                    <form onSubmit={submitIntroCard5Title}>
                        <input type='text' value={card5Title} onChange={updateCard5Title}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
    
                    <p>Description:</p>
    
                    <form onSubmit={submitIntroCard5Description}>
                        <input type='text' value={card5Description} onChange={updateCard5Description}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
                
    
    
                </div> 
    
                <div>
                    <h3>Sixth Card</h3>
                    <p>Title:</p>
    
                    <form onSubmit={submitIntroCard6Title}>
                        <input type='text' value={card6Title} onChange={updateCard6Title}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
    
                    <p>Description:</p>
    
                    <form onSubmit={submitIntroCard6Description}>
                        <input type='text' value={card6Description} onChange={updateCard6Description}/>
                        <Button type="submit" variant='warning'>Save</Button>
                    </form>
                
    
    
                </div> 
            
            
            
            </div>
                </div> 

                : <AlertPop />

            }
            
        
        </Fragment>
       
    )
}

export default CoursesUpdate;