import { initializeApp } from "firebase/app";

import { writeBatch ,getFirestore,collection,doc,getDoc,setDoc,updateDoc} from "firebase/firestore";

import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect,signInWithEmailAndPassword,createUserWithEmailAndPassword,onAuthStateChanged,signOut,FacebookAuthProvider,getRedirectResult } from "firebase/auth";

import { getStorage, ref, getDownloadURL,uploadBytes,list,uploadBytesResumable } from "firebase/storage";



const firebaseConfig = {
    apiKey: 'AIzaSyDzvhuY08ZGGyY2VOz_ZTj4QkI-2NgKby4',
    authDomain: "aainagirl.firebaseapp.com",
    projectId: "aainagirl",
    storageBucket: "aainagirl.appspot.com",
    messagingSenderId: "453121687141",
    appId: '1:453121687141:web:4236e6b0073ac300e1d08c',
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);

  export const storage = getStorage();

  // reference to image in storage location

  export const gsReference = ref(storage, 'gs://aainagirl.appspot.com/slider/ahguide-wbcs.jpg');
  
  const storageRef = ref(storage, 'ahguide.jpg');

// getting download link for image

 export const downloadImage = async()=>{
    const response = await getDownloadURL(storageRef);

    return response;

 }
 
 // uploading image to firebase storage 

 export const uploadImage = async(image)=>{



    await uploadBytes(storageRef, image)
                      .then((snapsort)=>{
                        alert('Uploading Done');
                      })


 }

 // uploading postimage to firebase storage 

 export const uploadPostImage = async(image)=>{

 

  const storageRef = ref(storage, `courses/${image.name}`);

  await uploadBytes(storageRef, image)
                    .then((snapsort)=>{
                      alert('Uploading Done');
                    })
                    

}
   
 
  
  // Send data to Database from Signin/SignUp
  
  const auth = getAuth();

  const GoogleProvider = new GoogleAuthProvider();
  
  export const GoogleLoginWithPopup =()=>signInWithPopup(auth,GoogleProvider);
  
  export const GoogleLoginWithRedirect=()=> signInWithRedirect(auth,GoogleProvider);
  

  export const getRedirectResultFromAuth =()=>getRedirectResult(auth);


  export const signInwithEmail = async(email,password)=>{
    return await signInWithEmailAndPassword(auth,email,password);
  }

  export const createUserwithEmail = (email,password)=>{
    return createUserWithEmailAndPassword(auth,email,password)
  }

  export const onAuthStateChangedListener = (callback)=>{
    onAuthStateChanged(auth,callback);
  }

  export const signOutUser = async() => await signOut(auth);

  export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (userAuth) => {
          unsubscribe();
          resolve(userAuth);
        },
        reject
      );
    });
  };


  const FacebookProvider = new FacebookAuthProvider();

  export const FacebookLoginWithPopup =()=>signInWithPopup(auth,FacebookProvider);


  export const createUserDocumentFromAuth = async (
    userAuth,
    additionalInformation = {}
  ) => {
    if (!userAuth) return;
  
    const userDocRef = doc(db, 'users', userAuth.uid);
  
    const userSnapshot = await getDoc(userDocRef);
  
    if (!userSnapshot.exists()) {
      const { displayName, email } = userAuth;
      const createdAt = new Date();
      const cartItems = [];
  
      try {
        await setDoc(userDocRef, {
          displayName,
          email,
          createdAt,
          cartItems,
          ...additionalInformation,
        });
      } catch (error) {
        console.log('error creating the user', error.message);
      }
    }
  
    return userSnapshot;
  }

  // Adding Home Intro Cards data to Database 

  export const addHomeIntroCardsData = async (
    collectionKey,
    objectsToAdd
  ) => {
    const batch = writeBatch(db);
    const collectionRef = collection(db, collectionKey);
    
    objectsToAdd.forEach((object) => {
       const docRef = doc(collectionRef, object.name.toLowerCase());
       batch.set(docRef, object.item);
    });
  
    await batch.commit();
    console.log('done');
  };

 
//Update Home Intro Title 

export const updatehomeIntroTitleFromBackend = async(title)=>{
  await updateDoc(doc(db,'introcards','introtitle'),{
    title : title
  })

}

//Update Home Intro Sub Header 

export const updatehomeIntroSubHeaderFromBackend = async(subheader)=>{
  await updateDoc(doc(db,'introcards','introsubheader'),{
    subheader : subheader
  })

}

//Update Home Intro Description 

export const updatehomeIntroDescriptionFromBackend = async(description)=>{
  await updateDoc(doc(db,'introcards','introdescription'),{
    description : description
  })

}


// Update Home Intro Card1 Data 

export const homeIntroCard1Title = async(title)=>{
  await updateDoc(doc(db,'introcards','card1'),{
    title : title
  })

}

// Update Home Intro Card1 Description 

export const homeIntroCard1Description = async(description)=>{
  await updateDoc(doc(db,'introcards','card1'),{
    text : description
  })

}

// Update Home Intro Card2 Data 

export const homeIntroCard2Title = async(title)=>{
  await updateDoc(doc(db,'introcards','card2'),{
    title : title
  })

}

// Update Home Intro Card2 Description 

export const homeIntroCard2Description = async(description)=>{
  await updateDoc(doc(db,'introcards','card2'),{
    text : description
  })

}

// Update Home Intro Card3 Data 

export const homeIntroCard3Title = async(title)=>{
  await updateDoc(doc(db,'introcards','card3'),{
    title : title
  })

}

// Update Home Intro Card3 Description 

export const homeIntroCard3Description = async(description)=>{
  await updateDoc(doc(db,'introcards','card3'),{
    text : description
  })

}

// Update Home Intro Card4 Data 

export const homeIntroCard4Title = async(title)=>{
  await updateDoc(doc(db,'introcards','card4'),{
    title : title
  })

}

// Update Home Intro Card4 Description 

export const homeIntroCard4Description = async(description)=>{
  await updateDoc(doc(db,'introcards','card4'),{
    text : description
  })

}


// Update Home Courses Title 

export const updateCoursesTitleFromBackend = async(title)=>{
  await updateDoc(doc(db,'courses','courseTitle'),{
    title : title
  })

}

// Update Home Courses Description 

export const updateCoursesDescriptionFromBackend = async(description)=>{
  await updateDoc(doc(db,'courses','courseDescription'),{
    description : description
  })

}

// Update Home Course1 Title 

 export const courseCard1Title = async(title)=>{
  await updateDoc(doc(db,'courses','course1'),{
    title : title
  })

}

// Update Home Course2 Title 

export const courseCard2Title = async(title)=>{
  await updateDoc(doc(db,'courses','course2'),{
    title : title
  })

}

// Update Home Course3 Title 

export const courseCard3Title = async(title)=>{
  await updateDoc(doc(db,'courses','course3'),{
    title : title
  })

}

// Update Home Course4 Title 

export const courseCard4Title = async(title)=>{
  await updateDoc(doc(db,'courses','course4'),{
    title : title
  })

}

// Update Home Course5 Title 

export const courseCard5Title = async(title)=>{
  await updateDoc(doc(db,'courses','course5'),{
    title : title
  })

}

// Update Home Course6 Title 

export const courseCard6Title = async(title)=>{
  await updateDoc(doc(db,'courses','course6'),{
    title : title
  })

}

// Update Home Course Card1 Description

export const homeCourseCard1Description = async(description)=>{
  await updateDoc(doc(db,'courses','course1'),{
    text : description
  })

}

// Update Home Course Card2 Description

export const homeCourseCard2Description = async(description)=>{
  await updateDoc(doc(db,'courses','course2'),{
    text : description
  })

}

// Update Home Course Card3 Description

export const homeCourseCard3Description = async(description)=>{
  await updateDoc(doc(db,'courses','course3'),{
    text : description
  })

}

// Update Home Course Card4 Description

export const homeCourseCard4Description = async(description)=>{
  await updateDoc(doc(db,'courses','course4'),{
    text : description
  })

}

// Update Home Course Card5 Description

export const homeCourseCard5Description = async(description)=>{
  await updateDoc(doc(db,'courses','course5'),{
    text : description
  })

}

// Update Home Course Card6 Description

export const homeCourseCard6Description = async(description)=>{
  await updateDoc(doc(db,'courses','course6'),{
    text : description
  })

}


// Update Contact Form Address from Backend 

export const updateContactFormAddressFromBackend = async(address)=>{
  await updateDoc(doc(db,'contactformdetails','address'),{
    address : address
  })

}

// Update Contact Form Email1 from Backend 

export const updateContactFormEmail1FromBackend = async(email1)=>{
  await updateDoc(doc(db,'contactformdetails','email1'),{
    email : email1
  })

}


// Update Contact Form Email2 from Backend 

export const updateContactFormEmail2FromBackend = async(email2)=>{
  await updateDoc(doc(db,'contactformdetails','email2'),{
    email : email2
  })

}


// Update Contact Form Mobile from Backend 

export const updateContactFormMobileFromBackend = async(mobile)=>{
  await updateDoc(doc(db,'contactformdetails','mobile'),{
    mobile : mobile
  })

}

// Update Footer Title/Description
 
export const updateFooterItemFromBackend = async(title,description)=>{
  await updateDoc(doc(db,'footer','item'),{
    title : title,
    description: description
  })

}

// Update Footer Contact Details
 
export const updateFooterContactDetailsFromBackend = async(title,address1,address2,mobile)=>{
  await updateDoc(doc(db,'footer','contact'),{
    title : title,
    address1: address1,
    address2: address2,
    mobile: mobile
  })

}

// get Wbcs Math Data
export const getWbcsMath = async()=>{
  const docRef = doc(db, 'wbcssubject', 'math');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Wbcs Math Data
export const setNewMathPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'math'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs Math Data
export const editMathPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'math'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs Math Data
export const deleteMathPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'math'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}


// get Wbcs Geography Data
export const getWbcsGeography = async()=>{
  const docRef = doc(db, 'wbcssubject', 'geography');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Wbcs Geography Data
export const setNewGeographyPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'geography'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs Geography Data
export const editGeographyPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'geography'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs Math Data
export const deleteGeographyPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'geography'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs History Data
export const getWbcsHistory = async()=>{
  const docRef = doc(db, 'wbcssubject', 'history');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Wbcs History Data
export const setNewHistoryPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'history'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs History Data
export const editHistoryPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'history'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs History Data
export const deleteHistoryPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'history'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs General Science Data
export const getWbcsGeneralScience = async()=>{
  const docRef = doc(db, 'wbcssubject', 'generalscience');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs General Science Data
export const setNewGeneralSciencePost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'generalscience'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs General Science Data
export const editGeneralSciencePost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'generalscience'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs General Science Data
export const deleteGeneralSciencePost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'generalscience'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs Polity Data
export const getWbcsPolity = async()=>{
  const docRef = doc(db, 'wbcssubject', 'polity');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs Polity Data
export const setNewPolityPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'polity'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs Polity Data
export const editPolityPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'polity'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs Polity Data
export const deletePolityPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'polity'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs Economy Data
export const getWbcsEconomy = async()=>{
  const docRef = doc(db, 'wbcssubject', 'economy');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs Economy Data
export const setNewEconomyPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'economy'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs Economy Data
export const editEconomyPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'economy'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs Economy Data
export const deleteEconomyPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'economy'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs GI Data
export const getWbcsGi = async()=>{
  const docRef = doc(db, 'wbcssubject', 'gi');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs GI Data
export const setNewGiPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'gi'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs GI Data
export const editGiPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'gi'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs GI Data
export const deleteGiPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'gi'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs Static Gk Data
export const getWbcsStaticGk = async()=>{
  const docRef = doc(db, 'wbcssubject', 'staticgk');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs Static GK Data
export const setNewStaticGkPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'staticgk'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs Staticgk Data
export const editStaticGkPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'staticgk'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Wbcs Static GK Data
export const deleteStaticGkPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'staticgk'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs CA Data
export const getWbcsCa = async()=>{
  const docRef = doc(db, 'wbcssubject', 'ca');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs CA Data
export const setNewCaPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'ca'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs CA Data
export const editCaPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'ca'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}



// Delete Wbcs Ca Data
export const deleteCaPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'ca'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}




// get Wbcs English Grammer Data
export const getWbcsEnglishGrammer = async()=>{
  const docRef = doc(db, 'wbcssubject', 'enggrammer');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs English Grammer Data
export const setNewEnglishGrammerPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'enggrammer'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs English Grammer Data
export const editEnglishGrammerPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'enggrammer'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}



// Delete Wbcs English Grammer Data
export const deleteEnglishGrammerPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'enggrammer'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Wbcs English Vocabulary Data
export const getWbcsEnglishVocabulary = async()=>{
  const docRef = doc(db, 'wbcssubject', 'engvocabulary');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Wbcs English Vocabulary Data
export const setNewEnglishVocabularyPost = async(newPost)=>{
  const docRef = doc(db, 'wbcssubject', 'engvocabulary'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Wbcs English Vocabulary Data
export const editEnglishVocabularyPost = async(editedObject,index)=>{
  const docRef = doc(db, 'wbcssubject', 'engvocabulary'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}



// Delete Wbcs English Vocabulary Data
export const deleteEnglishVocabularyPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'engvocabulary'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Ias Subject Data
export const getIasSubject = async()=>{
  const docRef = doc(db, 'wbcssubject', 'iasdata');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}

// Update Ias Subject Data
export const setNewIasSubjectPost = async(newPost)=>{
  const docRef = doc(db,'wbcssubject', 'iasdata'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Ias Subject Data
export const editIasSubjectPost = async(editedObject,index)=>{
  const docRef = doc(db,'wbcssubject', 'iasdata'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}



// Delete Ias Subject Data
export const deleteIasSubjectPost = async(index)=>{
  const docRef = doc(db, 'wbcssubject', 'iasdata'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}




// get Courses First Card Data
export const getCoursesFirstCard = async()=>{
  const docRef = doc(db, 'courses', 'firstcoursescard');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Courses First Card Data
export const setCoursesFirstCardNewPost = async(newPost)=>{
  const docRef = doc(db,'courses', 'firstcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Courses First Card Data
export const editCoursesFirstCardPost = async(editedObject,index)=>{
  const docRef = doc(db, 'courses', 'firstcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Courses First Card Data
export const deleteCoursesFirstCardPost = async(index)=>{
  const docRef = doc(db,'courses', 'firstcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}


// update Notification Data
export const updateNotificationData = async(text)=>{
  const docRef = doc(db,'notification','animated'); 
  await updateDoc(docRef,{
    text : text

  })

}

// get Courses Second Card Data
export const getCoursesSecondCard = async()=>{
  const docRef = doc(db, 'courses', 'secondcoursescard');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Courses Second Card Data
export const setCoursesSecondCardNewPost = async(newPost)=>{
  const docRef = doc(db,'courses', 'secondcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Courses Second Card Data
export const editCoursesSecondCardPost = async(editedObject,index)=>{
  const docRef = doc(db, 'courses', 'secondcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Courses Second Card Data
export const deleteCoursesSecondCardPost = async(index)=>{
  const docRef = doc(db,'courses', 'secondcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Courses Third Card Data
export const getCoursesThirdCard = async()=>{
  const docRef = doc(db, 'courses', 'thirdcoursescard');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Courses Third Card Data
export const setCoursesThirdCardNewPost = async(newPost)=>{
  const docRef = doc(db,'courses', 'thirdcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Courses Third Card Data
export const editCoursesThirdCardPost = async(editedObject,index)=>{
  const docRef = doc(db, 'courses', 'thirdcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Courses Third Card Data
export const deleteCoursesThirdCardPost = async(index)=>{
  const docRef = doc(db,'courses', 'thirdcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}


// get Courses Forth Card Data
export const getCoursesForthCard = async()=>{
  const docRef = doc(db, 'courses', 'forthcoursescard');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Courses Forth Card Data
export const setCoursesForthCardNewPost = async(newPost)=>{
  const docRef = doc(db,'courses', 'forthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Courses Third Card Data
export const editCoursesForthCardPost = async(editedObject,index)=>{
  const docRef = doc(db, 'courses', 'forthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Courses Forth Card Data
export const deleteCoursesForthCardPost = async(index)=>{
  const docRef = doc(db,'courses', 'forthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Courses Fifth Card Data
export const getCoursesFifthCard = async()=>{
  const docRef = doc(db, 'courses', 'fifthcoursescard');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Courses Fifth Card Data
export const setCoursesFifthCardNewPost = async(newPost)=>{
  const docRef = doc(db,'courses', 'fifthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Courses Fifth Card Data
export const editCoursesFifthCardPost = async(editedObject,index)=>{
  const docRef = doc(db, 'courses', 'fifthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Courses Fifth Card Data
export const deleteCoursesFifthCardPost = async(index)=>{
  const docRef = doc(db,'courses', 'fifthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}



// get Courses Sixth Card Data
export const getCoursesSixthCard = async()=>{
  const docRef = doc(db, 'courses', 'sixthcoursescard');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}
// Update Courses Sixth Card Data
export const setCoursesSixthCardNewPost = async(newPost)=>{
  const docRef = doc(db,'courses', 'sixthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Courses Sixth Card Data
export const editCoursesSixthCardPost = async(editedObject,index)=>{
  const docRef = doc(db, 'courses', 'sixthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Courses Sixth Card Data
export const deleteCoursesSixthCardPost = async(index)=>{
  const docRef = doc(db,'courses', 'sixthcoursescard'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

// get Notification Update Data
export const getNotificationUpdateData = async()=>{
  const docRef = doc(db, 'notification', 'update');   

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
}


// Update Notification Data
export const setNotificationData = async(newPost)=>{
  const docRef = doc(db,'notification', 'update'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}

// Edit Notification Data
export const editNotificationPost = async(editedObject,index)=>{
  const docRef = doc(db, 'notification', 'update'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1, editedObject);
  await setDoc(docRef,{
    data : data

  })

}


// Delete Notification Data
export const deleteNotificationPost = async(index)=>{
  const docRef = doc(db,'notification', 'update'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.splice(index, 1);
  await setDoc(docRef,{
    data : data

  })

}

export const getDowloadImageLink = async(file)=>{
  const storageRef = ref(storage, `/testimonial/${file.name}`);
 
  const uploadImage = await uploadBytes(storageRef, file);
  
  const response = await getDownloadURL(storageRef);

 return response;
  
}

// Update Testimonial data to Firestore
export const uploadTestimonial = async(newPost)=>{
  const docRef = doc(db, 'testimonial', 'alumni'); 
  const querySnapshot = await getDoc(docRef);
  const arrayData = querySnapshot.data();
  const {data} = arrayData;
  data.push(newPost);
  await setDoc(docRef,{
    data : data

  })

}