import './App.css';
import { Routes,Route } from 'react-router-dom';
import ContactFormUpdate from './backend/components/contact-form/contact-form.update';
import CoursesUpdate from './backend/components/courses/courses.update';
import IntroCard from './backend/components/intro-cards/intro-cards.update';
import SliderUpdate from './backend/components/slider/slider.update';
import FooterUpdate from './backend/components/footer/footer.update';
import AdminDashboard from './backend/home/admin-dashboard.component';
import Navigation from './backend/components/navigation/navigation.component';
import AdminLogin from './backend/admin/admin-login';
import MathSubjectUpdate from './backend/components/subjects/math/math.update';
import MathSubjectEdit from './backend/components/subjects/math/math.edit';
import MathCards from './backend/components/wbcs/math/math.cards';
import GeographyPostCreate from './backend/components/subjects/geography/georaphy.create';
import GeographyPostEdit from './backend/components/subjects/geography/geography.edit';
import GeographyCards from './backend/components/wbcs/geography/geography.cards';
import HistoryCards from './backend/components/wbcs/history/history.cards';
import HistoryPostCreate from './backend/components/subjects/history/history.create';
import HistoryPostEdit from './backend/components/subjects/history/history.edit';
import GeneralScienceCards from './backend/components/wbcs/general-science/general-science.cards';
import GeneralSciencePostEdit from './backend/components/subjects/general-science/general-science.edit';
import GeneralSciencePostCreate from './backend/components/subjects/general-science/general-science.create';
import CoursesFirstCardPostCreate from './backend/components/courses/cards/first/first-card.courses';
import CoursesFirstCards from './backend/components/courses/cards/first/first.cards';
import FirstCardPostEdit from './backend/components/courses/cards/first/first-card.edit';
import NotificationUpdate from './backend/components/notification/notification.update';
import PolityCards from './backend/components/wbcs/polity/polity.cards';
import PolityPostCreate from './backend/components/subjects/polity/polity.create';
import PolityPostEdit from './backend/components/subjects/polity/polity.edit';
import EconomyPostEdit from './backend/components/subjects/economy/economy.edit';
import EconomyPostCreate from './backend/components/subjects/economy/economy.create';
import EconomyCards from './backend/components/wbcs/economy/economy.cards';
import GiCards from './backend/components/wbcs/gi/gi.cards';
import GiPostCreate from './backend/components/subjects/gi/gi.create';
import GiPostEdit from './backend/components/subjects/gi/gi.edit';
import StaticGkCards from './backend/components/wbcs/static-gk/static-gk.cards';
import StaticGkPostCreate from './backend/components/subjects/static-gk/static-gk.create';
import StaticGkPostEdit from './backend/components/subjects/static-gk/static-gk.edit';
import CaPostCreate from './backend/components/subjects/ca/ca.create';
import CaPostEdit from './backend/components/subjects/ca/ca.edit';
import CaCards from './backend/components/wbcs/ca/ca.cards';
import CoursesSecondCardPostCreate from './backend/components/courses/cards/second/second-card.create';
import CoursesSecondCards from './backend/components/courses/cards/second/second.cards';
import SecondCardPostEdit from './backend/components/courses/cards/second/second-card.edit';
import CoursesThirdCardPostCreate from './backend/components/courses/cards/third/third-card.create';
import ThirdCardPostEdit from './backend/components/courses/cards/third/third-card.edit';
import CoursesThirdCards from './backend/components/courses/cards/third/third.cards';
import NotificationPostCreate from './backend/components/notification/notification.create';
import NotificationPostEdit from './backend/components/notification/notification.edit';
import NotificationCards from './backend/components/notification/notification.cards';
import EnglishGrammerPostCreate from './backend/components/subjects/english/english-grammer.create';
import EnglishGrammerPostEdit from './backend/components/subjects/english/english-grammer.edit';
import EnglishGrammerCards from './backend/components/wbcs/english/english-grammer.cards';
import EnglishVocabularyCards from './backend/components/wbcs/english/english-vocabulary.cards';
import EnglishVocabularyPostCreate from './backend/components/subjects/english/english-vocabulary.create';
import EnglishVocabularyPostEdit from './backend/components/subjects/english/english-vocabulary.edit';
import CoursesForthCardPostCreate from './backend/components/courses/cards/forth/forth-card.create';
import ForthCardPostEdit from './backend/components/courses/cards/forth/forth-card.edit';
import CoursesFourthCards from './backend/components/courses/cards/forth/fourth.cards';
import CoursesFifthCardPostCreate from './backend/components/courses/cards/fifth/fifth-card.create';
import FifthCardPostEdit from './backend/components/courses/cards/fifth/fifth-card.edit';
import CoursesFifthCards from './backend/components/courses/cards/fifth/fifth.cards';
import CoursesSixthCardPostCreate from './backend/components/courses/cards/sixth/sixth-card.create';
import SixthCardPostEdit from './backend/components/courses/cards/sixth/sixth-card.edit';
import CoursesSixthCards from './backend/components/courses/cards/sixth/sixth.cards';
import IasPostCreate from './backend/components/subjects/ias/ias.create';
import IasPostEdit from './backend/components/subjects/ias/ias.edit';
import IasCards from './backend/components/wbcs/ias/ias.cards';
import TestimonialCreate from './backend/components/testimonial/testimonial.create';


function App() {
  

  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
      <Route index element={<AdminLogin />} />
      <Route path='update-courses' element={<CoursesUpdate />}/>
      <Route path='update-intro-cards' element={<IntroCard />}/>
      <Route path='update-slider' element={<SliderUpdate />}/>
      <Route path='update-footer' element={<FooterUpdate />}/>
      <Route path='update-contact' element={<ContactFormUpdate />}/>
      <Route path='admin-login' element={<AdminLogin />}/>
      <Route path='dashboard' element={<AdminDashboard />}/>
      <Route path='wbcs/math' element={<MathCards />}/>
      <Route path='wbcs/geography' element={<GeographyCards />}/>
      <Route path='wbcs/history' element={<HistoryCards />}/>
      <Route path='wbcs/general-science' element={<GeneralScienceCards />}/>
      <Route path='wbcs/polity' element={<PolityCards />}/>
      <Route path='wbcs/economy' element={<EconomyCards />}/>
      <Route path='wbcs/gi' element={<GiCards />}/>
      <Route path='create-post-gi' element={<GiPostCreate />}/>
      <Route path='edit-post-gi' element={<GiPostEdit />}/>
      <Route path='wbcs/static-gk' element={<StaticGkCards />}/>
      <Route path='wbcs/ca' element={<CaCards />}/>
      <Route path='ias' element={<IasCards />}/>
      <Route path='english/grammer' element={<EnglishGrammerCards />}/>
      <Route path='english/vocabulary' element={<EnglishVocabularyCards />}/>
      <Route path='create-post-static-gk' element={<StaticGkPostCreate />}/>
      <Route path='edit-post-static-gk' element={<StaticGkPostEdit />}/>
      <Route path='create-post-math' element={<MathSubjectUpdate />}/>
      <Route path='edit-math-subject' element={<MathSubjectEdit />}/>
      <Route path='create-post-geography' element={<GeographyPostCreate />}/>
      <Route path='edit-post-geography' element={<GeographyPostEdit />}/>
      <Route path='create-post-history' element={<HistoryPostCreate />}/>
      <Route path='edit-post-history' element={<HistoryPostEdit />}/>
      <Route path='create-post-ias' element={<IasPostCreate />}/>
      <Route path='edit-post-ias' element={<IasPostEdit />}/>
      <Route path='create-post-first-card' element={<CoursesFirstCardPostCreate />}/>
      <Route path='courses/first' element={<CoursesFirstCards />}/>
      <Route path='edit-post-first-card' element={<FirstCardPostEdit />}/>
      <Route path='update-notification' element={<NotificationUpdate />}/>
      <Route path='create-post-general-science' element={<GeneralSciencePostCreate />}/>
      <Route path='edit-post-general-science' element={<GeneralSciencePostEdit />}/>
      <Route path='create-post-polity' element={<PolityPostCreate />}/>
      <Route path='edit-post-polity' element={<PolityPostEdit />}/>
      <Route path='create-post-economy' element={<EconomyPostCreate />}/>
      <Route path='edit-post-economy' element={<EconomyPostEdit />}/>
      <Route path='create-post-ca' element={<CaPostCreate />}/>
      <Route path='edit-post-ca' element={<CaPostEdit />}/>
      <Route path='create-post-english-grammer' element={<EnglishGrammerPostCreate />}/>
      <Route path='edit-post-english-grammer' element={<EnglishGrammerPostEdit />}/>
      <Route path='create-post-english-vocabulary' element={<EnglishVocabularyPostCreate />}/>
      <Route path='edit-post-english-vocabulary' element={<EnglishVocabularyPostEdit />}/>
      <Route path='create-post-second-card' element={<CoursesSecondCardPostCreate />}/>
      <Route path='edit-post-second-card' element={<SecondCardPostEdit />}/>
      <Route path='courses/second' element={<CoursesSecondCards />}/>
      <Route path='create-post-third-card' element={<CoursesThirdCardPostCreate />}/>
      <Route path='edit-post-third-card' element={<ThirdCardPostEdit />}/>
      <Route path='courses/third' element={<CoursesThirdCards />}/>
      <Route path='create-post-fourth-card' element={<CoursesForthCardPostCreate />}/>
      <Route path='edit-post-fourth-card' element={<ForthCardPostEdit />}/>
      <Route path='courses/fourth' element={<CoursesFourthCards />}/>
      <Route path='create-post-fifth-card' element={<CoursesFifthCardPostCreate />}/>
      <Route path='edit-post-fifth-card' element={<FifthCardPostEdit />}/>
      <Route path='courses/fifth' element={<CoursesFifthCards />}/>
      <Route path='create-post-sixth-card' element={<CoursesSixthCardPostCreate />}/>
      <Route path='edit-post-sixth-card' element={<SixthCardPostEdit />}/>
      <Route path='courses/sixth' element={<CoursesSixthCards />}/>
      
      
      
      <Route path='notification-page-update' element={<NotificationCards />}/>
      <Route path='create-post-notification' element={<NotificationPostCreate />}/>
      <Route path='edit-post-notification' element={<NotificationPostEdit />}/>
      <Route path='create-testimonial' element={<TestimonialCreate />}/>
      
      </Route>
    
      
      </Routes>
  );
}

export default App;
