import {CircularProgress} from '@mui/material';
import { useState,useEffect } from 'react';

const CircularDeterminate = ()=>{
    
   const [progress, setProgress] = useState(0);

   useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);
    
    
    
    return (
        <CircularProgress variant="determinate" value={progress} />  

    )
}

export default CircularDeterminate;