import { Fragment, useEffect,useState } from "react";
import { useContext } from 'react';
import { UserContext } from "../../contexts/user.contexts";
import { Button,Form,Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './admin.login.css';
import { signInwithEmail } from "../../firebase/firebase";

const defaultFields = {
  email :'',
  password: ''
}


const AdminLogin = ()=>{

    const { currentUser,errorCode, setErrorCode } = useContext(UserContext);

    const [showSpinner,setShowSpinner] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{ 
        setShowSpinner(false);
        if(currentUser){
          setErrorCode(null);
          setTimeout(()=>{
            navigate('/dashboard');
  
          },3000)
        }    
       

         if(errorCode){
         alert("Check Email and Password Combination");
        }
        

    },[errorCode,currentUser])

    
    

    const [field,setField]= useState(defaultFields);
    const {email,password} = field;

    

    
    const resetFormField = ()=>{
        setField(defaultFields);
    }

    const handleFieldValue = (event)=>{
        const{name,value} = event.target;
        setField({...field,[name]:value});
    }

    const handleSubmit = async(event)=>{
            setShowSpinner(true);
            event.preventDefault();
            try {
              await signInwithEmail(email,password);
            }
            catch (error){
              setErrorCode(error);

            }
            
            resetFormField();
                               
            }
     
    return (
        <Fragment>
          {currentUser ? <div><h1>Logged In</h1>
          
            <p>Please wait, redirecting to dashboard in 3 seconds.</p>
          </div>
          :         <div className="login-container">
          <Form className="form-container" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control onChange={handleFieldValue} type="email" placeholder="Enter email" value={email} name='email' />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control onChange={handleFieldValue} type="password" placeholder="Password" value={password} name='password' />
      </Form.Group>
      {showSpinner ?  <Spinner animation="border"variant="danger"/>
                   : <Button variant="danger" type="submit">
                      Sign In
                     </Button> }
     
      
      </Form>  
      
</div>
        }

</Fragment>
    )
}

export default AdminLogin;