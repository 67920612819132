import { useState,useContext } from "react";
import { UserContext } from "../../../contexts/user.contexts";
import { updateNotificationData } from "../../../firebase/firebase";
import { Typography,Box,Link,TextField, Stack, Button,Menu,MenuItem, Container,Drawer } from "@mui/material";
import CircularDeterminate from "../progress/circular-determinate/circular-determinate.component";
import AlertPop from "../../../alert/alert.component";

const NotificationUpdate =()=>{

    const {currentUser} = useContext(UserContext);

    const [progress,setProgress] = useState(false);

    const handleSubmit=async(event)=>{
        event.preventDefault();
        setProgress(true);
        const data = new FormData(event.currentTarget);
        
        // const postImage = data.get('image');  
        // await uploadPostImage(postImage);
        updateNotificationData(data.get('text'));
        alert('Updated');
        setProgress(false);


    }

    return(
      <Container>
        {
           !currentUser ? <AlertPop />

           :
           <Box>
            <Typography variant="h4" componentt="div" sx={{textAlign:'center',marginTop:'20px'}}>
               Update Notification
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="text"
              label="Text"
              name="text"
              autoComplete="text"
              autoFocus
            />
            {/* <TextField
              margin="normal"
              required
              
              fullWidth
              name="url"
              label="url"
              id="url"
              autoComplete="current-url"
            /> */}
             
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="image"
              label="Image"
              id="image"
              autoComplete="current-date"
              type="file"
            /> */}
            {
              progress ? <CircularDeterminate />

                    : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
            }
            
            
          </Box>

        </Box>
        }
     
        
        </Container>
    )

}

export default NotificationUpdate;