import { Fragment, useState,useContext } from "react";
import { homeIntroCard1Title,homeIntroCard1Description,homeIntroCard2Title,
    homeIntroCard2Description,
    homeIntroCard3Title,homeIntroCard4Title,
    homeIntroCard3Description,
    homeIntroCard4Description,
    updatehomeIntroTitleFromBackend,
    updatehomeIntroSubHeaderFromBackend,
    updatehomeIntroDescriptionFromBackend
} from "../../../firebase/firebase";

import { UserContext } from "../../../contexts/user.contexts";

import { Button } from "react-bootstrap";
import AlertPop from "../../../alert/alert.component";


const IntroCard = ()=> {

    const {currentUser} = useContext(UserContext);

    const [introTitle,SetIntroTitle] = useState('');

    const [introSubHeader,SetIntroSubHeader] = useState('');

    const [introDescription,SetIntroDescription] = useState('');

    const [card1Title,SetCard1Title] = useState('');

    const [card1Description,SetCard1Description] = useState('');

    const [card2Title,SetCard2Title] = useState('');

    const [card2Description,SetCard2Description] = useState('');

    const [card3Title,SetCard3Title] = useState('');

    const [card3Description,SetCard3Description] = useState('');

    const [card4Title,SetCard4Title] = useState('');
    
    const [card4Description,SetCard4Description] = useState('');

    
    const updateIntroTitle = (event)=>{
        const updatedTitle = event.target.value;
        SetIntroTitle(updatedTitle);
        
    }

    const updateIntroSubHeader = (event)=>{
        const updatedSubHeader = event.target.value;
        SetIntroSubHeader(updatedSubHeader);
        
    }

    const updateIntroDescription = (event)=>{
        const updatedDescription = event.target.value;
        SetIntroDescription(updatedDescription);
        
    }
    
    
    const updateCard1Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard1Title(updatedTitle);
        
    }

    const updateCard2Title = (event)=>{
        const updated2Title = event.target.value;
        SetCard2Title(updated2Title);
        
    }

    const updateCard3Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard3Title(updatedTitle);
        
    }

    const updateCard4Title = (event)=>{
        const updatedTitle = event.target.value;
        SetCard4Title(updatedTitle);
        
    }

    const updateCard1Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard1Description(updatedDescription);
        

    }

    const updateCard2Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard2Description(updatedDescription);
        

    }

    const updateCard3Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard3Description(updatedDescription);
        

    }

    const updateCard4Description = (event)=>{
        const updatedDescription = event.target.value;
        SetCard4Description(updatedDescription);
        
    }

    const submitIntroTitle=(event)=>{
        event.preventDefault();
        updatehomeIntroTitleFromBackend(introTitle);
        alert('Intro Title Updated');
        SetIntroTitle('');    

    }

    const submitIntroSubHeader=(event)=>{
        event.preventDefault();
        updatehomeIntroSubHeaderFromBackend(introSubHeader);
        alert('Sub Header Updated');
        SetIntroSubHeader('');    

    }

    const submitIntroDescription=(event)=>{
        event.preventDefault();
        updatehomeIntroDescriptionFromBackend(introDescription);
        alert('Intro Description Updated');
        SetIntroDescription('');    

    }


    const submitIntroCard1Title=(event)=>{
        event.preventDefault();
        homeIntroCard1Title(card1Title);
        alert('Title Updated');
        SetCard1Title('');    

    }

    const submitIntroCard2Title=(event)=>{
        event.preventDefault();
        homeIntroCard2Title(card2Title);
        alert('Title Updated');
        SetCard2Title('');    

    }

    const submitIntroCard3Title=(event)=>{
        event.preventDefault();
        homeIntroCard3Title(card3Title);
        alert('Title Updated');
        SetCard3Title('');    

    }

    const submitIntroCard4Title=(event)=>{
        event.preventDefault();
        homeIntroCard4Title(card4Title);
        alert('Title Updated');
        SetCard4Title('');    

    }


    const submitIntroCard1Description=(event)=>{
        event.preventDefault();
        homeIntroCard1Description(card1Description);
        alert('Description Updated');
        SetCard1Description('');  

    }

    const submitIntroCard2Description=(event)=>{
        event.preventDefault();
        homeIntroCard2Description(card2Description);
        alert('Description Updated');
        SetCard2Description('');  

    }

    const submitIntroCard3Description=(event)=>{
        event.preventDefault();
        homeIntroCard3Description(card3Description);
        alert('Description Updated');
        SetCard3Description('');  

    }

    const submitIntroCard4Description=(event)=>{
        event.preventDefault();
        homeIntroCard4Description(card4Description);
        alert('Description Updated');
        SetCard4Description('');  

    }

    return (
        <Fragment>
            {
                !currentUser ? <AlertPop />

                :
                <div>
        <h1 style={{textAlign:'center'}}>Update Home Intro Data</h1>
        <div style={{display:'flex',gap:20,margin:20}}>
            <div>
                <h3>Intro Title:</h3>
                <form onSubmit={submitIntroTitle}>
                    <input type='text' value={introTitle} onChange={updateIntroTitle}/>
                <Button type="submit" variant="warning">Save</Button>
                </form>

            </div>

            <div>
            <h3>Sub Header:</h3>

            <form onSubmit={submitIntroSubHeader}>
                <input type='text' value={introSubHeader} onChange={updateIntroSubHeader}/>
                <Button type="submit" variant='warning'>Save</Button>
            </form>

            </div>

                <div>
                <h3>Description:</h3>

                <form onSubmit={submitIntroDescription}>
                    <input type='text' value={introDescription} onChange={updateIntroDescription}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

                </div>
               
        </div>
        <div style={{margin:20,display:'flex',gap:20}}>
            
            <div>
                <h3>First Card</h3>
                <p>Title:</p>

                <form onSubmit={submitIntroCard1Title}>
                    <input type='text' value={card1Title} onChange={updateCard1Title}/>
                   <Button type="submit" variant="warning">Save</Button>
                </form>

                <p>Description:</p>

                <form onSubmit={submitIntroCard1Description}>
                    <input type='text' value={card1Description} onChange={updateCard1Description}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>
            


            </div> 

            <div>
                <h3>Second Card</h3>
                <p>Title:</p>

                <form onSubmit={submitIntroCard2Title}>
                    <input type='text' value={card2Title} onChange={updateCard2Title}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

                <p>Description:</p>

                <form onSubmit={submitIntroCard2Description}>
                    <input type='text' value={card2Description} onChange={updateCard2Description}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>
            


            </div> 

            <div>
                <h3>Third Card</h3>
                <p>Title:</p>

                <form onSubmit={submitIntroCard3Title}>
                    <input type='text' value={card3Title} onChange={updateCard3Title}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

                <p>Description:</p>

                <form onSubmit={submitIntroCard3Description}>
                    <input type='text' value={card3Description} onChange={updateCard3Description}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>
            


            </div> 

            <div>
                <h3>Fourth Card</h3>
                <p>Title:</p>

                <form onSubmit={submitIntroCard4Title}>
                    <input type='text' value={card4Title} onChange={updateCard4Title}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

                <p>Description:</p>

                <form onSubmit={submitIntroCard4Description}>
                    <input type='text' value={card4Description} onChange={updateCard4Description}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>
            


            </div> 
        
        
        
        </div>
        </div>

            }
        
        
        </Fragment>
       
    )
}

export default IntroCard;