import { Fragment,useState,useContext } from "react";
import { updateFooterItemFromBackend,updateFooterContactDetailsFromBackend } from "../../../firebase/firebase";
import { Button } from "react-bootstrap";
import { UserContext } from "../../../contexts/user.contexts";
import AlertPop from "../../../alert/alert.component";

const FooterUpdate = ()=>{

    const {currentUser} = useContext(UserContext);

    const defaultFields = {
        title: 'A H Guide',
        description: 'Get the Evidence of Success and Testimony of preparation with the Best WBCS Coaching.'
    }

    const defaultFooterContactItems = {
        contactTitle: 'A H Guide',
        address1: 'New Bus Stand,Rampurhat',
        address2: 'W.B-731224',
        mobile : 'Mobile: 7098230513'
    }

    const [footerItem,setFooterItem] = useState(defaultFields);
    const {title,description} = footerItem;
    
    const [footerContactItem,setFooterContactItem] = useState(defaultFooterContactItems);

    const {contactTitle,address1,address2,mobile} = footerContactItem;
    
    
    
    const handleFooterItemValue = (event)=>{
        const{name,value} = event.target;
        setFooterItem({...footerItem,[name]:value});
    }

    const submitFooterItem = (event)=>{
        event.preventDefault();
        updateFooterItemFromBackend(title,description);
        alert('Updated');
    }

    const handleFooterContactItemValue = (event)=>{
        const{name,value} = event.target;
        setFooterContactItem({...footerContactItem,[name]:value});
    }

    const submitFooterContactItem = (event)=>{
        event.preventDefault();
        updateFooterContactDetailsFromBackend(contactTitle,address1,address2,mobile);
        alert('Updated');


    }

    return (
        <Fragment>
            {
                !currentUser ? <AlertPop />

                :
                <div>
            <h1 style={{margin:30,textAlign:'center'}}>Footer Update</h1>
            <div style={{display:'flex',gap:40,margin:20,flexDirection:'column'}}>
                <div>
                    <form onSubmit={submitFooterItem}>
                        <label>
                            Title: 
                            <input type='text' value={title} name='title' onChange={handleFooterItemValue}/>
                        </label>&nbsp;&nbsp;
                        <label>
                            Description: 
                            <input type='text' value={description} name='description' onChange={handleFooterItemValue}/>
                        </label>                        
                        <Button type="submit" variant="warning">Save</Button>
                    </form>

                

                </div>

                <div>
                    <form onSubmit={submitFooterContactItem}>
                        <label>
                            Title: 
                            <input type='text' value={contactTitle} name='contactTitle' onChange={handleFooterContactItemValue}/>
                        </label>&nbsp;&nbsp;
                        <label>
                            Address1: 
                            <input type='text' value={address1} name='address1' onChange={handleFooterContactItemValue}/>
                        </label>&nbsp;&nbsp;
                        <label>
                            Address2: 
                            <input type='text' value={address2} name='address2' onChange={handleFooterContactItemValue}/>
                        </label><br/>                      
                        <label>
                            Mobile: 
                            <input type='text' value={mobile} name='mobile' onChange={handleFooterContactItemValue}/>
                        </label><br/> 
                        <Button type="submit" variant="warning">Save</Button>
                    </form>

                

                </div>
            </div>
            </div>

            }
            
            

        </Fragment>
        
    )
}

export default FooterUpdate;