import { Alert,Button } from "react-bootstrap";
import { useState } from "react";

const AlertPop = ()=>{
    const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Oh snap! Authentication Required</Alert.Heading>
        <p>
          Please <Alert.Link href='/admin-login'> Login </Alert.Link>to view this Page!
        </p>
      </Alert>
    );
  }
}

export default AlertPop;