import { uploadImage } from "../../../firebase/firebase";
import { Fragment, useContext } from "react";
import { UserContext } from "../../../contexts/user.contexts";
import AlertPop from "../../../alert/alert.component";

const SliderUpdate = ()=>{

    const {currentUser} = useContext(UserContext);

    


    const handleSubmit=async(event)=>{
        event.preventDefault();
        const file = event.target[0].files[0];
        await uploadImage(file);
    
    }

    return (
        <Fragment>
        {
            !currentUser ? <AlertPop />

            : 
            <div style={{margin:20}}>
            <h1 style={{textAlign:'center'}}>Slider Update</h1><br/>
            <p>Please click on browse button to select image file then click on upload button </p>
            <form onSubmit={handleSubmit}>
              <input type='file' />
              <button type="submit">Upload</button>
              
              </form> 
              <br />
              <br />
          </div>
        }
        </Fragment>
        
    )
}

export default SliderUpdate;