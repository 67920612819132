import { useState,useContext } from "react";
import { UserContext } from "../../../../contexts/user.contexts";
import { setNewMathPost,uploadPostImage } from "../../../../firebase/firebase";
import { Typography,Box,Link,TextField, Stack, Button,Menu,MenuItem, Container,Drawer } from "@mui/material";
import CircularDeterminate from "../../progress/circular-determinate/circular-determinate.component";
import AlertPop from "../../../../alert/alert.component";

const MathSubjectUpdate =()=>{

    const {currentUser} = useContext(UserContext);

    const [progress,setProgress] = useState(false);

    const handleSubmit=async(event)=>{
        event.preventDefault();
        setProgress(true);
        const data = new FormData(event.currentTarget);
        const newPostData = {
          title: data.get('title'),
          description: data.get('description'),
          date: data.get('date'),
          }
        // const postImage = data.get('image');  
        // await uploadPostImage(postImage);
        setNewMathPost(newPostData);
        alert('Updated');
        setProgress(false);


    }

    return(
      <Container>
        {
           !currentUser ? <AlertPop />

           :
           <Box>
            <Typography variant="h4" componentt="div" sx={{textAlign:'center',marginTop:'20px'}}>
                CREATE NEW POST(MATH)
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              
              fullWidth
              name="description"
              label="Description"
              id="description"
              autoComplete="current-description"
            />
             <TextField
              margin="normal"
              required
              fullWidth
              name="date"
              label="Date"
              id="date"
              autoComplete="current-date"
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="image"
              label="Image"
              id="image"
              autoComplete="current-date"
              type="file"
            /> */}
            {
              progress ? <CircularDeterminate />

                    : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
            }
            
            
          </Box>

        </Box>
        }
     
        
        </Container>
    )

}

export default MathSubjectUpdate;