import { Fragment, useState,useContext,useEffect } from "react";
import { UserContext } from "../../../../contexts/user.contexts";
import { getWbcsCa,editCaPost,deleteCaPost } from "../../../../firebase/firebase";
import { Typography,Box,TextField, Button } from "@mui/material";
import CircularDeterminate from "../../progress/circular-determinate/circular-determinate.component";
import AlertPop from "../../../../alert/alert.component";
import { Container } from "@mui/system";

const CaPostEdit =()=>{

    const {currentUser} = useContext(UserContext);

    const [progress1,setProgress1] = useState(false);

    const [progress2,setProgress2] = useState(false);

    const [mathArray,setMathArray] = useState([]);

    const [arrayIndex,setArrayIndex]= useState(null);

    useEffect(()=>{
        const getMathData=async()=>{
            const snapShot = await getWbcsCa();
            const {data} = snapShot;
            setMathArray(data);
        }
        getMathData();
    },[])

    const handleSubmit=async(event)=>{
        event.preventDefault();
        setProgress1(true);
        const data = new FormData(event.currentTarget);
        const searchData = data.get('search');
        const editingElementIndex = mathArray.findIndex((arr)=>{
            return arr.title===searchData;
            
        })
        setArrayIndex(editingElementIndex);       
        setProgress1(false);
        if(editingElementIndex===-1){
            alert('Opps no match found')
        }
        else {
            alert('Match Found, Please fill below details to edit')
        }

    }

    const handleDataEdit=async(event)=>{
        event.preventDefault();
        setProgress2(true);
        const data = new FormData(event.currentTarget);
        const editedPostData = {
          title: data.get('title'),
          description: data.get('description'),
          date: data.get('date'),
        }
         await editCaPost(editedPostData,arrayIndex);
         alert("Details Updated")
         setProgress2(false);
        
    }

    const handleDelete=async(event)=>{
        event.preventDefault();
        setProgress1(true);
        const data = new FormData(event.currentTarget);
        const searchData = data.get('search');
        const editingElementIndex = mathArray.findIndex((arr)=>{
            return arr.title===searchData;
            
        })
        setArrayIndex(editingElementIndex);       
        setProgress1(false);
        if(editingElementIndex===-1){
            alert('Opps no match found')
        }
        else {
            await deleteCaPost(editingElementIndex);
            alert('Post Deleted')
        }

    }

    return(
      <Container>
        {
           !currentUser ? <AlertPop />

           :<Box>
           <Box>
            <Typography variant="h4" componentt="div" sx={{textAlign:'center',marginTop:'20px'}}>
                SEARCH OLD POST(CA)
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              type='search'
              id="search"
              label="Post Title"
              name="search"
              autoComplete="search"
              autoFocus
            />
            
            {
              progress1 ? <CircularDeterminate />

                    : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Search
                  </Button>
            }
            
            
          </Box>

        </Box>
        <Box>
            <Typography component="div" variant="h4"  sx={{textAlign:'center',marginTop:'20px'}}>
                    EDIT POST(CA)
            </Typography>
            <Box onSubmit={handleDataEdit} component="form">
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Post Title"
              name="title"
              autoComplete="title"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="description"
              label="Description"
              id="description"
              autoComplete="current-description"
            />
             <TextField
              margin="normal"
              required
              fullWidth
              name="date"
              label="Date"
              id="date"
              autoComplete="current-date"
            />
            {
              progress2 ? <CircularDeterminate />

                    : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
            }

            </Box>
            
        </Box>

        <Box>
        <Typography variant="h5" componentt="div"  sx={{textAlign:'center',marginTop:'20px'}}>
                 DELETE POST(CA)
            </Typography>
            <Box component="form" onSubmit={handleDelete} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              type='search'
              id="search"
              label="Post Title"
              name="search"
              autoComplete="search"
              autoFocus
            />
            
            {
              progress1 ? <CircularDeterminate />

                    : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Delete
                  </Button>
            }
            
            
          </Box>


        </Box>
        
        
        </Box>

        }
     
        
        </Container>
    )

}

export default CaPostEdit;