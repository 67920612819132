import { Fragment,useContext } from "react";
import { Nav,Navbar,Container, Button,NavDropdown } from "react-bootstrap";
import { Navigate, Outlet,useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/user.contexts";
import { signOutUser } from "../../../firebase/firebase";

const Navigation = ()=>{

    const {currentUser} = useContext(UserContext);

    const navigate = useNavigate();

    const handleSignin=()=>{
      navigate('/admin-login');
    }

    return (
        <Fragment>
        <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">Ahguide Admin</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard">Home</Nav.Link>
              <Nav.Link href="/update-slider">Slider</Nav.Link>
              <Nav.Link href="/update-intro-cards">Intro</Nav.Link>
              <Nav.Link href="/update-courses">Courses</Nav.Link>
              <Nav.Link href="/update-footer">Footer</Nav.Link>
              <Nav.Link href="/update-contact">Contact</Nav.Link>
              <NavDropdown title="WBCS" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/wbcs/math">Math</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/geography">Geography</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/history">History</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/general-science">General Science</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/polity">Polity</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/economy">Economy</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/gi">GI</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/static-gk">Static Gk</NavDropdown.Item>
                  <NavDropdown.Item href="/wbcs/ca">CA</NavDropdown.Item>
                  <NavDropdown.Item href="/ias">IAS</NavDropdown.Item>
                
              </NavDropdown>

              <NavDropdown title="English" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/english/grammer">Grammer</NavDropdown.Item>
                  <NavDropdown.Item href="/english/vocabulary">Vocabulary</NavDropdown.Item>
                  
                
              </NavDropdown>

              <NavDropdown title="Courses Cards" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/courses/first">First</NavDropdown.Item>
                  <NavDropdown.Item href="/courses/second">Second</NavDropdown.Item>
                  <NavDropdown.Item href="/courses/third">Third</NavDropdown.Item>
                  <NavDropdown.Item href="/courses/fourth">Fourth</NavDropdown.Item>
                  <NavDropdown.Item href="/courses/fifth">Fifth</NavDropdown.Item>
                  <NavDropdown.Item href="/courses/sixth">Sixth</NavDropdown.Item>
                
              </NavDropdown>
              <NavDropdown title="Notification" id="navbarScrollingDropdown">
              <Nav.Link href="/notification-page-update">Create or Update Page</Nav.Link>
              <Nav.Link href="/update-notification">Home Text Update</Nav.Link>


              </NavDropdown>
             
              
              
              {currentUser ? <Button variant="warning" onClick={signOutUser}>Signout</Button>
                          : <Button variant="warning" onClick={handleSignin}>Signin</Button>  
            
            }
              
             
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      </Fragment>

    )
}

export default Navigation;