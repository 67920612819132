import { CardContent,Container,Button,Box,CardActions,Stack,Typography } from "@mui/material";


const EnglishGrammerCards = ()=>{
    return (
        <Container maxWidth="sm" sx={{marginTop:10}}>
                <Stack spacing={3}>

                
                <Box sx={{ width: 400 , border:'1px solid black'}}>
                <CardContent variant="outlined">
                    <Typography component="div" variant="h4" gutterBottom>
                        Create Post
                    </Typography>
                    <CardActions>
                    <Button href="/create-post-english-grammer" size="small">Click here</Button>
                    </CardActions>
                    </CardContent>
                </Box>
                   
                   
                <Box sx={{ width: 400 , border:'1px solid black'}}>
                <CardContent variant="outlined">
                    <Typography component="div" variant="h4" gutterBottom>
                        Edit or Delete Post
                    </Typography>
                    <CardActions>
                    <Button size="small" href="/edit-post-english-grammer">Click here</Button>
                    </CardActions>
                    </CardContent>
                </Box>
                    </Stack>
            

            </Container>

        

    )

}

export default EnglishGrammerCards;