import { Fragment,useState,useContext } from "react";
import { UserContext } from "../../../contexts/user.contexts";
import { Button } from "react-bootstrap";
import { updateContactFormAddressFromBackend,
         updateContactFormEmail1FromBackend,
         updateContactFormEmail2FromBackend,
         updateContactFormMobileFromBackend

} from "../../../firebase/firebase";

import AlertPop from "../../../alert/alert.component";

const ContactFormUpdate = ()=>{

    const {currentUser} = useContext(UserContext);

    const [address,SetAddress] = useState('');

    const [email1,SetEmail1] = useState('');
  
    const [email2,SetEmail2] = useState('');
  
    const [contactMobile,SetContactMobile] = useState('');

    const updateContactAddress=(event)=>{
        const updatedAddress = event.target.value;
        SetAddress(updatedAddress);
    }

    const updateContactEmail1=(event)=>{
        const updatedEmail1 = event.target.value;
        SetEmail1(updatedEmail1);
    }

    const updateContactEmail2=(event)=>{
        const updatedEmail2 = event.target.value;
        SetEmail2(updatedEmail2);
    }

    const updateContactMobile=(event)=>{
        const updatedMobile = event.target.value;
        SetContactMobile(updatedMobile);
    }

    const submitContactAddress=(event)=>{
        event.preventDefault();
        updateContactFormAddressFromBackend(address);
        alert('Address Updated');
        SetAddress('');    

    }

    const submitContactFormEmail1=(event)=>{
        event.preventDefault();
        updateContactFormEmail1FromBackend(email1);
        alert('Email1 Updated');
        SetEmail1('');    

    }

    const submitContactFormEmail2=(event)=>{
        event.preventDefault();
        updateContactFormEmail2FromBackend(email2);
        alert('Email2 Updated');
        SetEmail2('');    

    }

    const submitContactFormMobile=(event)=>{
        event.preventDefault();
        updateContactFormMobileFromBackend(contactMobile);
        alert('Mobile Number Updated');
        SetContactMobile('');    

    }


    return (
        <Fragment>
            
            {
                !currentUser ? <AlertPop />

                :
                
            <div>
                 <h1 style={{margin:30,textAlign:'center'}}>Contact Form Fields Update</h1>
<div style={{display:'flex',gap:20,margin:20}}>
                <div>
                <h3>Address:</h3>

                    <form onSubmit={submitContactAddress}>
                        <input type='text' value={address} onChange={updateContactAddress}/>
                    <Button type="submit" variant="warning">Save</Button>
                    </form>

                </div>
               <div>
               <h3>Email1:</h3>

                <form onSubmit={submitContactFormEmail1}>
                    <input type='text' value={email1} onChange={updateContactEmail1}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

               </div>
               <div>
               <h3>Email2:</h3>

                <form onSubmit={submitContactFormEmail2}>
                    <input type='text' value={email2} onChange={updateContactEmail2}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

               </div>                

               <div>
               <h3>Mobile:</h3>

                <form onSubmit={submitContactFormMobile}>
                    <input type='text' value={contactMobile} onChange={updateContactMobile}/>
                    <Button type="submit" variant='warning'>Save</Button>
                </form>

               </div>
            </div>
            </div>   
            
                

            }
            

        </Fragment>
    )
}

export default ContactFormUpdate;