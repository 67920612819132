import { useState,useContext } from "react";
import { UserContext } from "../../../contexts/user.contexts";
import { getDowloadImageLink, setNewGeographyPost, uploadTestimonial } from "../../../firebase/firebase";
import { Typography,Box,Link,TextField, Stack, Button,Menu,MenuItem, Container,Drawer } from "@mui/material";
import CircularDeterminate from "../progress/circular-determinate/circular-determinate.component";
import AlertPop from "../../../alert/alert.component";


const TestimonialCreate =()=>{

    const {currentUser} = useContext(UserContext);
    const [file,setFile] = useState("");
    const [url,setUrl] = useState("");

    const [progress,setProgress] = useState(false);


   

    const handleSubmit=async(event)=>{
        event.preventDefault();
        setProgress(true);
        const data = new FormData(event.currentTarget);
        // console.log(data.get('image'));
        const newPostData = {
          title: data.get('title'),
          description: data.get('description'),
          url
         
          }
        
        // setNewGeographyPost(newPostData);
        uploadTestimonial(newPostData);
        alert('Updated');
       

        setProgress(false);
        setTimeout(() => {
          document.location.reload();
        }, 3000);


    }

    const handleFileChange=(e)=> {
      setFile(e.target.files[0]);
    }

    const handleUpload=async(e)=>{
      e.preventDefault();
      setProgress(true);
      if (!file) {
        alert("Please upload an image first!");
    }

   const imageLink = await getDowloadImageLink(file);
   
   alert("Image Uploaded Successfully");
   setProgress(false);
   setUrl(imageLink);

    }

    return(
      <Container>
        {
           !currentUser ? <AlertPop />

           :
           <Box>
            <Typography variant="h4" componentt="div" sx={{textAlign:'center',marginTop:'20px'}}>
                CREATE NEW TESTIMONIAL
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} method="post">
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Name"
              name="title"
              autoComplete="title"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              
              fullWidth
              name="description"
              label="Description"
              id="description"
              autoComplete="current-description"
            />
             
            <TextField
              margin="normal"
              required
              fullWidth
              name="image"
              label="Image"
              id="image"
              autoComplete="image"
              type="file"
              
              onChange={handleFileChange}
              
            />
             <button onClick={handleUpload}>Upload</button>
            {
              progress ? <CircularDeterminate />

                    : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
            }
            
            
          </Box>

        </Box>
        }
     
        
        </Container>
    )

}

export default TestimonialCreate;
